import { useState, useEffect, useMemo, useCallback } from 'react';
import logo from './logo.svg';
import './App.css';
import 'react-data-grid/lib/styles.css';
import DataGrid from 'react-data-grid';
import { algod } from './config.js';

const INCENTIVES = false;

async function getLastRound() {
  try {
    const { "last-round": lr } = await algod.status().do();
    return lr;
  } catch(e) {
    throw new Error(`While fetching latest round: ${e.message}`);
  }
}

async function fetchOne(url) {
  try {
    const resp = await fetch(url);
    if (!resp.ok) {
      const t = await resp.text();
      const code = resp.status;
      const msg = `Error ${code} from ${url}: ${t}`;
      throw new Error(msg);
    }
    return await resp.json();
  } catch(e) {
    throw new Error(`${e.message} ${url}`);
  }
}

function serializeValue(value: unknown, i: string | undefined) {
  if (i) {
    if (i.key === "lvts") {
      return new Date(value * 1000).toISOString();
    }
  }
  if (typeof value === 'string') {
    const formattedValue = value.replace(/"/g, '""');
    return formattedValue.includes(',') ? `"${formattedValue}"` : formattedValue;
  }
  return value;
}

function downloadFile(fileName: string, data: Blob) {
  const downloadLink = document.createElement('a');
  downloadLink.download = fileName;
  const url = URL.createObjectURL(data);
  downloadLink.href = url;
  downloadLink.click();
  URL.revokeObjectURL(url);
}

export async function exportToCsv(columns, rows, fileName) {
  const content = [columns.map(({name}) => serializeValue(name)).join(",")];
  content.push(...rows.map(row => 
    columns.map(({key}, i) => serializeValue(row[key] ?? '', columns[i])).join(",")
  ));
  downloadFile(fileName, new Blob([content.join("\n")], { type: 'text/csv;charset=utf-8;' }));
}

function HFlex({ style, children }) {
  return <div style={{display: 'flex', flexDirection: 'row', ...style}}>{children}</div>
}

function format(field, fn) {
  return ({row}) => fn({value: row[field]})
}

function BooleanFormatter({ value, ...a }) {
  if (value)
    return <>Yes</>;
  return <>No</>;
}

function CurrencyFormatter({ value }) {
  if (!Number.isFinite(value))
    return value;
  return <>Ⱥ {value.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</>
}

function DateFormatter({ value, ...r }) {
  if (value > 0) {
    const v = new Date(value * 1000).toLocaleString();
    return <>{v}</>;
  } else {
    return <>Expired</>
  }
}

function PercentFormatter({ value }) {
  if (!Number.isFinite(value))
    return value;
  return <>{value.toFixed(2)}%</>
}

const known = [
  'Algorand Inc',
  'Algorand Foundation',
  'NFDomains',
];

function parseAddressBookOwner(owner) {
  for(const k of known) {
    if (owner.includes(k))
      return k
  }
  return owner;
}

function getComparator(sortColumn: string): Comparator {
  return (a, b) => {
    return a[sortColumn] === b[sortColumn] ? 0 : a[sortColumn] < b[sortColumn] ? 1 : -1;
  };
}

async function getProposerData(first, last) {
  return fetchOne(`https://mainnet-analytics.d13.co/v0/proposers?minRound=${first}&maxRound=${last}`);
}

function App() {
  const [columns, setColumns] = useState([
    { key: 'a', name: 'Address' },
    { key: 'o', name: 'Owner' },
    { key: 'v', name: 'DeFi' },
    INCENTIVES ? { key: 'ie', name: 'Inc. Eligible', formatter: format('ie', BooleanFormatter) } : null,
    { key: 'b', name: 'Balance', formatter: format('b', CurrencyFormatter) },
    { key: 'nob', name: 'N.O. Balance', formatter: format('nob', CurrencyFormatter) },
    { key: 'perc', name: 'Voting %', formatter: format('perc', PercentFormatter) },
    { key: 'proposals_all', name: 'Blocks (All time)', },
    INCENTIVES ? { key: 'payouts_all', name: 'Ⱥ Payouts (All)', formatter: format('payouts_all', CurrencyFormatter) } : null,
    { key: 'proposals_100k', name: 'Blocks (Last 100K)', },
    INCENTIVES ? { key: 'payouts_100k', name: 'Ⱥ Payouts (100K)', formatter: format('payouts_100k', CurrencyFormatter) } : null,
    { key: 'proposals_1m', name: 'Blocks (Last 1M)', },
    INCENTIVES ? { key: 'payouts_1m', name: 'Ⱥ Payouts (1M)', formatter: format('payouts_1m', CurrencyFormatter) } : null,
    { key: 'ur', name: 'Update Round' },
    { key: 'fv', name: 'First Valid' },
    { key: 'lv2', name: 'Last Valid' },
    { key: 'lvts', name: 'Est. Expiration', formatter: format('lvts', DateFormatter) },
    { key: 'kd', name: 'Key Dilution' },
  ].filter(Boolean));
  const [rows, setRows] = useState([]);
  const [updated, setUpdated] = useState(null);
  const [sortColumns, setSortColumns] = useState([{ columnKey: "b", direction: "ASC" }]);
  const [totals, setTotals] = useState({});
  const [addressBook, setAddressBook] = useState();
  const [error, setError] = useState();
  
  const rerender = useCallback(() => {
    setColumns([...columns]);
  }, [columns]);

  useEffect(() => {
  }, [sortColumns]);

  // const topRow = useMemo(() => {
  //   return [{ a: 'Total (${totals.count})', b: 2, nob: 3}];
  // }, [totals]);

  const sortedRows = useMemo(() => {
    if (sortColumns.length === 0) return rows;
    const sorted = [...rows].sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult;
        }
      }
      return 0;
    });
    return sorted;
  }, [rows, sortColumns]);

  useEffect(() => {
    (async() => {
      try {
        const lastRound = await getLastRound();
        const firstRound1M = lastRound - 1_000_000 + 1;
        const firstRound100K = lastRound - 100_000 + 1;
        const [addr1, addr2, data, vaults, proposersAll, proposers1M, proposers100K] = await Promise.all([
          fetchOne("https://flow.algo.surf/address-book.json"),
          fetchOne("/address-book.json"),
          fetchOne('https://cons-data.pages.dev/latest.json'),
          fetchOne('https://cons-data.pages.dev/vaults.json'),
          getProposerData(1, lastRound),
          firstRound1M > 0 ? getProposerData(firstRound1M, lastRound) : null,
          firstRound100K > 0 ? getProposerData(firstRound100K, lastRound) : null,
        ]);
        const addressBook = {...addr1, ...addr2};
        setAddressBook(addressBook);
        setUpdated(data.ts);
        const totalB = data.onl.reduce((sum, {b}) => sum+b, 0);
        const totalNOB = data.onl.reduce((sum, {nob}) => sum+nob, 0);
        setTotals({b: totalB, nob: totalNOB, count: data.onl.length});
        const nextRows = data.onl.map(({a, ie, nob, b, fv, lv2, lvts, kd, ur, Z}) => {
          let o = '';
          let v = 'No';
          if (addressBook[a]) {
            const label = parseAddressBookOwner(addressBook[a]);
            o = label;
          } 
          if (vaults[a]) {
            const vData = vaults[a];
            let {v: version} = vData;
            if (version === 'ff')
              v = 'Yes (Folks)'
            else
              v = `Yes (AlgoFi v${version})`;
            if (!o)
              o = vData.owner;
          }
          const obj = {
            a,
            o,
            b,
            ie: !!ie,
            nob,
            perc: b / totalB * 100,
            fv,
            lv2,
            lvts,
            kd,
            ur,
            v,
          };

          const addr = a;
          for(const [label, proposers] of [['all', proposersAll], ['1m', proposers1M], ['100k', proposers100K]]) {
            const proposerData = proposers.find(({proposer: p}) => p === addr);
            obj['proposals_'+label] = proposerData?.blocks ?? 0;
            obj['payouts_'+label] = (proposerData?.payouts ?? 0)/1e6;
          }
          return obj;
        });
        setRows(nextRows);
      } catch(e) {
        setError(e.message);
      }
    })()
  }, []);

  const gridElement = <DataGrid
    columns={columns}
    rows={sortedRows}
    onRowsChange={setRows}
    sortColumns={sortColumns}
    onSortColumnsChange={setSortColumns}
    defaultColumnOptions={{
      sortable: true,
      resizable: true
    }}
    /* topSummaryRows={topRow} */
    className="fill-grid"
    rowKeyGetter={rowKeyGetter}
    style={{flexGrow: 1, resize: 'both'}} />;

  const exportAction = useCallback(() => exportToCsv(columns, sortedRows, 'algorand-consensus.csv'), [columns, sortedRows]);

  return (
    <div className="App">
      <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
        <HFlex>
          <div style={{flexGrow: 1, textAlign: 'left'}}>Algorand Consensus Participation Data &middot; <a href="https://nitter.net/d13_co/status/1684400652691152900">D13.co [?]</a> &middot; Updated: {new Date(updated).toLocaleString()}</div>
          <button onClick={exportAction}>Export</button>
        </HFlex>
        { error ? <HFlex style={{justifyContent: "center", alignItems: "center", flexGrow: 1,}}>Error: {error}</HFlex> : gridElement}
      </div>
    </div>
  );
}

function rowKeyGetter(row: Row) {
  return row.a;
}

export default App;
